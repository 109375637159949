"use client";

import { setLocation } from "@/lib/centra/hooks/useChangeLocation/useChangeLocation";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Suspense, useEffect, useState } from "react";

/**
 * https://blstworld.com/product/saksa-insulated-jacket-archive?currency=usd&amp;stay=1&amp;country=us&amp;color=1380
 * TO: https://localhost:3000/product/saksa-insulated-jacket-archive?currency=usd&amp%3Bstay=1&amp%3Bcountry=us&amp%3Bcolor=1380
 * Transforms a google merchant feed url into and sets its location properly
 */
const UnsuspendedSetLocation = () => {
  const token = usePersistedStore((state) => state.token);
  const queryClient = useQueryClient();

  const [country, setCountry] = useState<string | null>(null);

  useEffect(() => {
    const url = new URL(
      decodeURIComponent(window.location.href).replaceAll("&amp;", "&"),
    );
    const country = url.searchParams.get("country");
    const currency = url.searchParams.get("currency");
    const stay = url.searchParams.get("stay");

    if (country && currency && stay) {
      setCountry(country);
    }
  }, []);

  useQuery({
    queryKey: ["setCountry", country, token],
    queryFn: async () => {
      if (!country) return;
      await queryClient.cancelQueries({ queryKey: ["selection"] });

      await setLocation(
        {
          locationId: country,
        },
        token,
      );
      await queryClient.invalidateQueries({ queryKey: ["selection"] });
      return {
        success: true,
      };
    },
    enabled: !!country && !!token,
    staleTime: Infinity,
  });

  return null;
};

export const SetLocation = () => {
  return (
    <Suspense>
      <UnsuspendedSetLocation />
    </Suspense>
  );
};
